
import { getClientByPhone, sendMessage } from '@root/src/api/connectors/yClients'
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { sendMassMail } from '../../../api/mailing'
import Select from '../../inputs/Select.vue'
import TextField from '../../inputs/TextField.vue'

export default defineComponent({
    components: { TextField, Select },
    props: ['connector'],
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const phone = ref('')
        const sendMessageLoading = ref(false)
        const selectBotLoading = ref(false)
        const messageSended = ref(false)
        const sendMessageState = ref<'arrived' | 'failed' | 'pending'>('pending')
        const botsList = ref([
            {
                id: 'beutyBot',
                name: 'БьютиБот',
            },
            {
                id: 'flowSell',
                name: 'FlowSell',
            },
            {
                id: 'other',
                name: 'Другой',
            },
        ])
        const selectedBot = ref({ id: null })

        const selectBot = async (v: any) => {
            selectBotLoading.value = true
            if (v.id === 'beutyBot') {
                await root.$store.dispatch('project/updateConnectorSettings', {
                    connectorId: props.connector.connectorId,
                    settings: { sendMessagePrefix: '#' },
                })
            }
            if (v.id === 'flowSell') {
                await root.$store.dispatch('project/updateConnectorSettings', {
                    connectorId: props.connector.connectorId,
                    settings: { sendMessagePrefix: null },
                })
            }
            if (v.id === 'other') {
                await root.$store.dispatch('project/updateConnectorSettings', {
                    connectorId: props.connector.connectorId,
                    settings: { sendMessagePrefix: null },
                })
            }
            selectBotLoading.value = false
        }
        const sendMesage = async () => {
            sendMessageLoading.value = true
            const { data: client, error: getClientError } = await getClientByPhone(
                props.connector.connectorId,
                phone.value
            )
            if (getClientError) {
                root.$store.dispatch('callNotify', 'Клиент не найден')
                sendMessageLoading.value = false
                return
            }
            const { data: sendMessageData, error: senMessageError } = await sendMassMail({
                projectId: props.connector.projectId,
                connectorId: props.connector.connectorId,
                service: 'yClients',
                messages: [
                    {
                        from: props.connector.companyIds[0],
                        to: client!.id,
                        text: 'Тестовое сообщение',
                        communicationId: null,
                    },
                ],
            })
            if (senMessageError) {
                root.$store.dispatch('callNotify', 'Ошибка при отправке сообщения')
                sendMessageLoading.value = false
                return
            }
            messageSended.value = true
            sendMessageLoading.value = false
        }
        return {
            phone,
            sendMesage,
            sendMessageLoading,
            messageSended,
            sendMessageState,
            botsList,
            selectedBot,
            selectBotLoading,
            selectBot,
        }
    },
})
